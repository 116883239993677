import React from "react"

export const withReturns = Component => ({ name = "Returns", page, uri }) => {
  const { title } = page

  const paths = [
    {
      title,
    },
  ]

  Component.displayName = name
  return <Component paths={paths} uri={uri} />
}
