import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { Returns as Page } from "../../components/Customer/Returns/Returns"

export const query = graphql`
  query {
    page: sanityPageAccountReturns {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = withAuthentication(({ data, ...props }): JSX.Element => <Page {...props} {...data} />)
export default Component
