import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withReturnsCard = Component => ({ name = "ReturnCard" }) => {
  const { returns } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_RETURNS {
      returns: sanityPageAccountReturns {
        title
        content: _rawContent(resolveReferences: { maxDepth: 2 })
      }
    }
  `)

  const { title, content } = returns || {}

  Component.displayName = name
  return <Component title={title} content={content} />
}
